import { useEffect, useState } from "react";
import "./Projects.css";
import { api, apiSecure } from "../../components/api";
import { Link } from "react-router-dom";
import { App, Button, Card, DatePicker, Flex, Input, Select } from "antd";
import { Content } from "antd/es/layout/layout";

function Projects() {
  const [projects, setProjects] = useState([]);
  const [search, setSearch] = useState('');
  const [stateValue, setStateValue] = useState('free');
  const { message } = App.useApp();

  const getProjects = () => {
    console.log("getProjects");

    let req_config = {
      method: "GET",
      url: `/projects`,
    };

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);
        setProjects(response.data.data.projects);
      })
      .catch((error) => {
        console.log("Error:", error);
        message.error('Projektien haku epäonnistui!');
      });
  };

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <Content style={{  backgroundColor: "#282828" }}>
      <div style={{marginTop:'1em', marginBottom:'2em', marginLeft:'10px'}}>

      <Input
        placeholder="Hae projektia"
        allowClear
        onChange={(e) => {
          console.log(e.target.value)
          setSearch(e.target.value)
        }}
        style={{ width: 200 }}
      />
      
      <Select
        style={{ width: 120, marginLeft:'2em' }}
        onChange={(value) => {
          console.log(`selected ${value}`);
          setStateValue(value)
        }}
        value={stateValue}
        options={[
          { value: "free", label: "Työn alla" },
          { value: "archived", label: "Arkistoitu" },
        ]}
      />
      </div>

      <Flex
        wrap="wrap"
        gap="small"
        style={{ marginTop: "20px", margin: "10px" }}
      >
        {projects?.length > 0 &&
          projects
          .sort((a, b) => a.name.localeCompare(b.name))
          .filter((item, index) => {

            let searchFilter = item.name.toLowerCase().includes(search.toLowerCase())

            let stateFilter = (item.archived === 0 && stateValue == 'free') || (item.archived === 1 && stateValue == 'archived');

            return searchFilter && stateFilter
          })
          .map((item, index) => {
            return (
              <div key={index}>
                <Link to={"project/" + item.id}>
                  <Card
                    title={item.name}
                    bordered={false}
                    style={{ width: 300 }}
                    bodyStyle={{
                      padding: "10px",
                      paddingLeft: "25px"}}
                  >
{/*                     <p>
                      Tila: 
                      {item.archived ? (
                        <span style={{marginLeft:'5px', color: "red", fontWeight:'bold' }}>Arkistoitu</span>
                      ) : (
                        <span style={{marginLeft:'5px', color: "green", fontWeight:'bold' }}>Työn alla</span>
                      )}
                    </p> */}
  
                    <p style={{fontSize: '12px'}}>
                      Sisältää aliprojekteja:
                      {item.haveSubProjects ? (
                        <span style={{marginLeft:'5px', color: "green", fontWeight:'bold' }}>Kyllä</span>
                      ) : (
                        <span style={{marginLeft:'5px', color: "red", fontWeight:'bold' }}>Ei</span>
                      )}
                    </p>
                  </Card>
                </Link>
              </div>
            );
          })}
      </Flex>
    </Content>
  );
}

export default Projects;
