import { Link, useParams } from "react-router-dom";
import "./Project.css";
import { api, apiSecure } from "../../components/api";
import { useEffect, useRef, useState } from "react";
import { Content } from "antd/es/layout/layout";
import { App, Button, Card, Dropdown, Input, Modal } from "antd";
import { FileModal } from "./FileModal";
import { ExclamationCircleFilled, PlusOutlined } from "@ant-design/icons";
import { AddContactModal } from "./AddContactModal";
import { EditContactModal } from "./EditContactModal";
import { AddWorksModal } from "./AddWorksModal";

function Project() {
  let { projectId } = useParams();
  const [projectData, setProjectData] = useState({});
  const [projectWorks, setProjectWorks] = useState({});
  const [projectContacts, setProjectContancts] = useState({});
  const [isFileModalOpen, setIsFileModalOpen] = useState(false);

  //Contacts
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [isEditContactModalOpen, setIsEditContactModalOpen] = useState(false);
  const [editContactData, setEditContactData] = useState({});

  //Works
  const [isAddWorksModalOpen, setIsAddWorksModalOpen] = useState(false);

  

  const [fileData, setFileData] = useState({});
  const refFileInput = useRef();
  
  const { modal, message } = App.useApp();

  console.log("projectId", projectId);

  const getProject = () => {
    console.log("getProject");

    let req_config = {
      method: "POST",
      url: `/getProject`,
      data: {
        projectId: projectId,
      },
    };

    apiSecure(req_config)
      .then((response) => {
        console.log("getProject RESPONSE", response.data);
        setProjectData(response.data.data);
      })
      .catch((error) => {
        console.log("Error:", error);
        message.error("Projektin haku epäonnistui!");
      });
  };

  const getProjectWorks = () => {
    let req_config = {
      method: "POST",
      url: `/getProjectWorks`,
      data: {
        projectId: projectId,
      },
    };

    apiSecure(req_config)
      .then((response) => {
        console.log("getProjectWorks RESPONSE", response.data);
        setProjectWorks(response.data.data);
      })
      .catch((error) => {
        console.log("Error:", error);
        message.error("Projektin haku epäonnistui!");
      });
  };

  const getProjectContacts = () => {
    let req_config = {
      method: "POST",
      url: `/getProjectContacts`,
      data: {
        projectId: projectId,
      },
    };

    apiSecure(req_config)
      .then((response) => {
        console.log("getProjectContacts RESPONSE", response.data);
        setProjectContancts(response.data.data);
      })
      .catch((error) => {
        console.log("Error:", error);
        message.error("Projektin haku epäonnistui!");
      });
  };

  useEffect(() => {
    if (!projectId) return;
    getProject();
    getProjectWorks();
    getProjectContacts();
  }, [projectId]);

  const deleteFile = (fileid) => {
    console.log("deleteFile", fileid);

    let req_config = {
      method: "POST",
      url: `/deleteProjectFile`,
      data: {
        fileid: fileid,
      },
    };

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);
        getProject();
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const deleteContact = (contactid) => {
    console.log("deleteContact", contactid);

    let req_config = {
      method: "POST",
      url: `/deleteProjectContact`,
      data: {
        contactId: contactid,
      },
    };

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);
        getProjectContacts();
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const acceptedTypes = ["image/png", "image/jpeg", "application/pdf"];

  const onFileChange = async (event) => {
    let selectedFile = event.target.files[0];
    console.log("selectedFile", selectedFile);

    if (!acceptedTypes.includes(selectedFile.type)) {
      console.log("Filetype error", selectedFile.type);
      refFileInput.current.value = "";
      return;
    }

    var fileName = selectedFile?.name;
    var fileExt = fileName.split(".").pop();

    let [canUpload, editedFileName] = await showUpload(
      selectedFile.name,
      fileExt
    );
    console.log("tes11", canUpload, editedFileName);

    if (!canUpload) {
      refFileInput.current.value = "";
      return;
    }
    if (editedFileName !== "") {
      fileName = editedFileName + "." + fileExt;
    }

    console.log("LÄPI", selectedFile);

    const formData = new FormData();

    formData.append("uploaded_file", selectedFile, fileName);

    formData.append("id", projectId);

    // Display the values
    // Display the key/value pairs
    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1].toString());
    }

    let req_config = {
      method: "POST",
      url: `/uploadProjectFile`,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);
        refFileInput.current.value = "";
        getProject();
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };
  /*   const onClick = (item) => {
    console.log(`Click on item`,item);
  }; */

  const showDeleteConfirm = (fileName, fileId) => {
    modal.confirm({
      title: "Haluatko poistaa tiedoston?",
      icon: <ExclamationCircleFilled />,
      content: fileName,
      okText: "Kyllä",
      okType: "danger",
      cancelText: "Ei",
      onOk() {
        console.log("OK");
        deleteFile(fileId);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };


  const showDeleteConfirmContact = (contactName, contactId) => {
    modal.confirm({
      title: "Haluatko poistaa yhteystiedon?",
      icon: <ExclamationCircleFilled />,
      content: contactName,
      okText: "Kyllä",
      okType: "danger",
      cancelText: "Ei",
      onOk() {
        console.log("OK");
        deleteContact(contactId);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const showUpload = (fileName, fileExt) => {
    return new Promise((resolve, reject) => {
      let filetest = "";

      modal.confirm({
        title: "Haluatko lisätä tiedoston?",
        icon: <ExclamationCircleFilled />,
        content: (
          <>
            <p>Nimi: {fileName}</p>
            <br />

            <p>Vaihda nimi:</p>
            <Input
              onChange={(e) => {
                console.log(e.target.value);
                filetest = e.target.value;
              }}
              suffix={"." + fileExt}
            />
            <br />
          </>
        ),
        okText: "Kyllä",
        okType: "danger",
        cancelText: "Ei",
        onOk() {
          resolve([true, filetest]);
        },
        onCancel() {
          console.log("Cancel");
          resolve([false]);
        },
      });
    });
  };

  return (
    <Content style={{ backgroundColor: "#282828" }}>
      <FileModal
        fileData={fileData}
        isModalOpen={isFileModalOpen}
        setIsModalOpen={setIsFileModalOpen}
      />

      <AddContactModal
        projectId={projectData?.tiedot?.id}
        isModalOpen={isContactModalOpen}
        setIsModalOpen={setIsContactModalOpen}
        refreshData={()=>{
          getProjectContacts();
        }}
      />

      <EditContactModal
        editContactData={editContactData}
        isModalOpen={isEditContactModalOpen}
        setIsModalOpen={setIsEditContactModalOpen}
        refreshData={()=>{
          getProjectContacts();
        }}
      />

      <AddWorksModal
        projectId={projectData?.tiedot?.id}
        isModalOpen={isAddWorksModalOpen}
        setIsModalOpen={setIsAddWorksModalOpen}
        refreshData={()=>{
          getProjectWorks();
        }}
      />


      <Card
        title={projectData?.tiedot?.name}
        style={{ margin: "4em", borderRadius: "12px", height: "60vh" }}
      >
        <div
          style={{
            padding: "1px",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Card
            title="Tiedot"
            bordered={false}
            style={{
              flex: 1,
              marginRight: "5px",
              marginLeft: "5px",
              borderRadius: "12px",
              backgroundColor: "#0C0C0C",
              fontSize: "16px",
            }}
          >
            <div style={{ display: "flex" }}>
              <p>Nimi:</p>
              <p style={{ marginLeft: "5px", fontWeight: "bold" }}>
                {projectData?.tiedot?.name}
              </p>
            </div>

            <div style={{ display: "flex", marginTop: "5px" }}>
              <p>Osoite:</p>
              <p style={{ marginLeft: "5px", fontWeight: "bold" }}>
                {projectData?.tiedot?.address}
              </p>
            </div>

            <div style={{ display: "flex", marginTop: "5px" }}>
              <p>Postiosoite:</p>
              <p style={{ marginLeft: "5px", fontWeight: "bold" }}>
                {projectData?.tiedot?.postaddress}
              </p>
            </div>

            <div style={{ display: "flex", marginTop: "5px" }}>
              <p>Aikataulu:</p>
              <p style={{ marginLeft: "5px", fontWeight: "bold" }}>
                {projectData?.tiedot?.schedule}
              </p>
            </div>

            <div style={{ display: "flex", marginTop: "5px" }}>
              <p>Arkistoitu:</p>

              {projectData?.tiedot?.archived ? (
                <span
                  style={{
                    marginLeft: "5px",
                    color: "red",
                    fontWeight: "bold",
                  }}
                >
                  Kyllä
                </span>
              ) : (
                <span
                  style={{
                    marginLeft: "5px",
                    color: "green",
                    fontWeight: "bold",
                  }}
                >
                  Ei
                </span>
              )}
            </div>
          </Card>

          {!projectData?.tiedot?.haveSubProjects && (
            <Card
              title="Tilatut työt"
              bordered={false}
              style={{
                flex: 1,
                marginRight: "5px",
                marginLeft: "5px",
                borderRadius: "12px",
                backgroundColor: "#0C0C0C",
              }}
              extra={
                <>
                    <PlusOutlined
                      onClick={() => {
                        setIsAddWorksModalOpen(true)
                        console.log("asd");
                      }}
                    />
      
                </>
              }
            >
              {projectWorks.length >= 0 &&
                projectWorks.map((itm) => {
                  return (
                    <>
                      <p>{itm.name}</p>
                    </>
                  );
                })}
            </Card>
          )}

          <Card
            title="Yhteystiedot"
            bordered={false}
            style={{
              flex: 1,
              marginRight: "5px",
              marginLeft: "5px",
              borderRadius: "12px",
              backgroundColor: "#0C0C0C",
            }}
            extra={
              <>
                  <PlusOutlined
                    onClick={() => {
                      setIsContactModalOpen(true)
                      console.log("asd");
                    }}
                  />
    
              </>
            }
          >
            {projectContacts.length >= 0 &&
              projectContacts.map((item) => {
                return (
                  <>

<Dropdown
                        key={item.id}
                        menu={{
                          items: [
                            {
                              label: item.name,
                              key: "name",
                              disabled: true,
                            },
                            /* 
                        {
                          label: "Lataa tiedosto",
                          key: "download",
                        }, */
                            {
                              label: "Poista yhteystieto",
                              key: "delete",
                              danger: true,
                              onClick: () => {
                                showDeleteConfirmContact(
                                  item.name,
                                  item.id
                                ); /* deleteFile(item.id) */
                              },
                            },
                          ],
                        }}
                        trigger={["contextMenu"]}
                      >
                        <Button
                          onClick={() => {
                            console.log(item);
                            setEditContactData(item)
                            setIsEditContactModalOpen(true)
                          }}
                          style={{ marginBottom: "10px" }}
                        >
                         <p>{item?.name}</p> 
                        </Button>
                      </Dropdown>



                  </>
                );
              })}
          </Card>

          {projectData?.tiedot?.haveSubProjects ? (
            <Card
              title="Aliprojektit"
              bordered={false}
              style={{
                flex: 1,
                marginRight: "5px",
                marginLeft: "5px",
                borderRadius: "12px",
                maxHeight: "40vh",
                overflow: "scroll",
                overflowX: "hidden",
                backgroundColor: "#0C0C0C",
              }}
            >
              {projectData?.subProjects?.length > 0 &&
                (projectData?.subProjects).map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{ marginTop: "5px", marginBottom: "5px" }}
                    >
                      <Link to={"../project/" + item.id}>
                        <Button
                          type="primary"
                          style={
                            item.archived
                              ? { backgroundColor: "#f38ba8" }
                              : { backgroundColor: "#313244" }
                          }
                        >
                          {item.name}
                        </Button>
                      </Link>
                    </div>
                  );
                })}
            </Card>
          ) : (
            <Card
              title="Tiedostot"
              bordered={false}
              style={{
                flex: 1,
                marginRight: "5px",
                marginLeft: "5px",
                borderRadius: "12px",
                backgroundColor: "#0C0C0C",
              }}
              extra={
                <>
                  <label htmlFor="file-upload" className="custom-file-upload">
                    <PlusOutlined
                      onClick={() => {
                        console.log("asd");
                      }}
                    />
                  </label>
                  <input
                    id="file-upload"
                    ref={refFileInput}
                    type="file"
                    accept=".jpg, .jpeg, .png, .pdf"
                    onChange={onFileChange}
                    style={{ display: "none" }}
                  />
                </>
              }
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  maxHeight: "300px",
                  overflow: "auto",
                  overflowX: "hidden",
                }}
              >
                {projectData?.files?.length > 0 &&
                  (projectData?.files).map((item, index) => {
                    return (
                      <Dropdown
                        key={item.id}
                        menu={{
                          items: [
                            {
                              label: item.name,
                              key: "name",
                              disabled: true,
                            },
                            /* 
                        {
                          label: "Lataa tiedosto",
                          key: "download",
                        }, */
                            {
                              label: "Poista tiedosto",
                              key: "delete",
                              danger: true,
                              onClick: () => {
                                showDeleteConfirm(
                                  item.name,
                                  item.id
                                ); /* deleteFile(item.id) */
                              },
                            },
                          ],
                        }}
                        trigger={["contextMenu"]}
                      >
                        <Button
                          onClick={() => {
                            console.log(item);
                            setFileData({ id: item.id });
                            setIsFileModalOpen(true);
                          }}
                          style={{ marginBottom: "10px" }}
                        >
                          {item.name}
                        </Button>
                      </Dropdown>
                    );
                  })}
              </div>
            </Card>
          )}
        </div>
      </Card>
    </Content>
  );
}

export default Project;
