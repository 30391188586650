import "./Login.css";
import { api } from "../../components/api";
import { Content } from "antd/es/layout/layout";
import {
  App,
  Button,
  Form,
  Input,
} from "antd";
import logo from "./pr-logo-big.png"; // Tell webpack this JS file uses this image
import { useUserStore } from "../../state/state";
import { useNavigate } from "react-router-dom";

function Login() {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const tryLogin = async (values) => {
    const {username, password } = values
    console.log("username:", username);
    console.log("password:", password);

    let req_config = {
      method: "POST",
      url: `/login`,
      data: {
        web: true,
        email: username,
        password: password,
        pushtoken: '',
      },
    };
  
    const response = await api.request(req_config).catch(async (e) => {
      console.log("[getUser] catch((e)", e);
    });

    console.log('test response', response)

    const { data } = response;


    if (data.message === "error") {
      message.error('Kirjautuminen epäonnistui!');
    }



    if (data.message === "user_loggedin") {
      message.success('Kirjautuminen onnistui!');
      //Data lokaalisti niin ei tarvitse joka kerta kirjautua uudelleen
      localStorage.setItem("USER_AUTH", JSON.stringify({
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
        authenticated: true,
        email: username,
      }))


      // Updating state, will trigger listeners
      useUserStore.setState({ authenticated: true, email:username})

      navigate('/')
    }
  };

  return (

    <Content style={{ height: "100vh", backgroundColor: "#282828" }}>
      <div className="background-image" />

      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "100vh",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            zIndex: 999,
            backgroundColor: "rgba(28, 28, 28, 0.98  )",
            paddingLeft: 50,
            paddingRight: 50,
            paddingTop: 30,
            paddingBottom: 20,
            borderRadius: 15,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ marginBottom: "2em" }}>
            <img src={logo} alt="Logo" style={{ height: "40px" }} />
          </div>

          <Form
            layout="vertical"
            name="basic"
            style={{
              width: "100%",
              color:'red'
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={tryLogin}
            autoComplete="off"
          >
            <Form.Item
        label={<label style={{ color: "white" }}>Käyttäjätunnus</label>}
        name="username"
              rules={[
                {
                  required: true,
                  message: "Syötä Käyttäjätunnus!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
        label={<label style={{ color: "white" }}>Salasana</label>}
        name="password"
              rules={[
                {
                  required: true,
                  message: "Syötä salasana!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">
                Kirjaudu
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Content>
  );
}

export default Login;
