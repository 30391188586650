import { useCallback, useEffect, useState } from "react";
import "./Schedule.css";
import { DrawTooltip, drawLine } from "./ScheduleSharedFunc";
import 'react-tooltip/dist/react-tooltip.css'

import Timeline, {
  CustomMarker,
  DateHeader,
  SidebarHeader,
  TimelineHeaders,
} from "react-calendar-timeline";

import holidays from "./test.json";
let holidays2 = holidays.map((hld) => hld.date);

// make sure you include the timeline stylesheet or the timeline will not be styled
import "react-calendar-timeline/lib/Timeline.css";
import moment from "moment";
import "moment/locale/fi";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import { AddWorkModal } from "./AddWorkModal";
import { PlusCircleOutlined } from "@ant-design/icons";
import { apiSecure } from "../../components/api";
import { EditWorkModal } from "./EditWorkModal";
import { App } from "antd";

function Schedule() {
  const [linesLoading, setLinesLoading] = useState(false);
  const { message } = App.useApp();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [addWorkData, setAddWorkData] = useState({
    workId: null,
    worker: [],
  });


  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editWorkData, setEditWorkData] = useState({
    workId: null,
    worker: [],
  });


  const [projects, setProjects] = useState([]);

  const [lineData, setLineData] = useState([]);
  const [groups, setGroups] = useState([]);

  const [workRows, setWorkRows] = useState([]);

  const TODAY = moment();
  const DAY = 1000 * 60 * 60 * 24;
  const WEEK = DAY * 7;
  const MONTH = WEEK * 4;

  const MIN_ZOOM = WEEK * 3;
  const MAX_ZOOM = MONTH * 6;

  const getWorks = () => {
    console.log("getProject");
    setLinesLoading(true)
    let req_config = {
      method: "POST",
      url: `/getCalendarWorks`,
      data: {},
    };

    apiSecure(req_config)
      .then((response) => {
        console.log("getWorks RESPONSE", response.data);
        // setProjectData(response.data.data);

        /*         let nRows = response.data.works.map((itm) => {
          return 
        })
 */
        setWorkRows(response.data.data.works);
      })
      .catch((error) => {
        console.log("Error:", error);
        message.error('Merkintöjen haku epäonnistui!');
      });
  };

  const getGroups = () => {
    console.log("getGroups");

    let req_config = {
      method: "POST",
      url: `/getCalendarGroups`,
      data: {},
    };

    apiSecure(req_config)
      .then((response) => {
        console.log("getGroups RESPONSE", response.data);
        let data12 = response?.data?.data;
        let groupUsers = data12?.map((itm) => {
          return {
            id: itm.id,
            title: `${itm.firstname} ${itm.lastname}`,
          };
        });

        console.log("groupUsers", groupUsers);

        setGroups(groupUsers);

        // setProjectData(response.data.data);
      })
      .catch((error) => {
        console.log("Error:", error);
        message.error('Työntekijöiden haku epäonnistui!');
      });
  };

  const getProjects = () => {
    console.log("getGroups");

    let req_config = {
      method: "POST",
      url: `/getCalendarProjects`,
      data: {},
    };

    apiSecure(req_config)
      .then((response) => {
        console.log("getProjects RESPONSE", response.data);
        let data12 = response?.data?.data;
        setProjects(data12);
      })
      .catch((error) => {
        console.log("Error:", error);
        message.error('Projektien haku epäonnistui!');
      });
  };

  useEffect(() => {
    getWorks();
  }, []);

  useEffect(() => {
    getGroups();
  }, []);

  useEffect(() => {
    getProjects();
  }, []);

  function isWeekend(wDate) {
    if (wDate.getDay() === 0 || wDate.getDay() === 6) {
      return true;
    } else return false;
  }

  function isHoliday(wDate) {
    return holidays2.includes(moment(wDate).format("YYYY-MM-DD"));
  }

  useEffect(() => {
    console.log("workRows length", workRows?.length);
    console.log("workRows test", workRows);

    if (workRows?.length <= 0) {
      setLinesLoading(false)
      return;
    }
    let items = [];

     groups.forEach((item, index) => {
      console.log("groupItem", item);

      holidays
        .filter((item2) => item2.kind_id === "1")
        .forEach((item2, index) => {
          drawLine(
            items,
            item.id,
            item2.title,
            item2.date,
            item2.date,
            "holiday",
            {},
            true
          );
        });
    });
 
    workRows.forEach((item, index) => {
      let days = item.hours / 8;


      let startDate = moment(item.start).hours(1);

      console.log("renderitem", item);
      console.log("item start", startDate.format("L"));
      console.log("item hours", item.hours, days);
      console.log("item days", days);


      let currentStart = null;
      var currentDate = startDate.toDate();
      var drawedDates = 0;


      console.log("currentDate123", currentDate);


      console.log("currentStart", currentStart);

      while (drawedDates < days) {
        if (!isWeekend(currentDate) && !isHoliday(currentDate)) {
          drawedDates++;
          if (currentStart === null) {
            currentStart = new Date(currentDate);
          }
        } else {
          if (currentStart != null) {
            drawLine(
              items,
              item.userid,
              item.projectname,//item.title,
              moment(currentStart),
              moment(currentDate).add("days", -1).hours(23),
              "work",
              {workData: item},
              false
            );
            currentStart = null;
          }
        }
        currentDate.setDate(currentDate.getDate() + 1);
      }

      //end
      if (currentStart != null) {
        drawLine(
          items,
          item.userid,
          item.projectname,//item.title,
          moment(currentStart),
          moment(currentDate.setDate(currentDate.getDate() - 1)).hours(23),
          "work",
          {workData: item},
          false
        );
        currentStart = null;
      }
    });

    console.log("workRows", items);
    setLineData(items);
    setLinesLoading(false)
  }, [workRows]);


// Yksinkertainen hash-funktio, joka muuntaa merkkijonon numeroksi
const stringToHash = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
};

// Funktio, joka generoi pastellivärin
const getPastelColor = (projectId, projectName) => {
  const combinedHash = stringToHash(projectId.toString() + projectName);
  const hue = combinedHash % 360; // Hue-arvo 0-360
  const saturation = 60 + (combinedHash % 20); // Saturaatio 60-80%
  const lightness = 75 + (combinedHash % 10); // Kirkkaus 85-95%
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};

  
  
  const groupRenderer = ({ group }) => {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        {group.title}
        <PlusCircleOutlined
          onClick={() => {
            setAddWorkData({ worker: [group.id] });
            setIsAddModalOpen(true);
          }}
          style={{ marginLeft: "auto", marginRight: "10px" }}
        />
      </div>
    );
  };

  const itemRenderer = useCallback(({
    item,
    timelineContext,
    itemContext,
    getItemProps,
    getResizeProps,
  }) => {
    let itemProps = getItemProps({});

        let tooltipTypes = {
      work: true,
/*       vacation: true,
      holiday: true,
      install: true,
      install_proj: true, */
    };

    let showTooltip = tooltipTypes[item.type];

    let projectId = item?.workData?.projectid ? item.workData.projectid : 0

    return (
      <>
        <div
          data-tooltip-id={"tooltip-" + item.id}

          {...itemProps}
          style={{
            ...itemProps.style,
            backgroundColor:
              item.type === "holiday" ? "#f54576" : getPastelColor(projectId, item.title),
            border: "unset",
            borderRadius: 2,
          }}
          onMouseDown={(e) => {
            if (e.detail == 1) {
              //console.log("on item click", item);
            }

            if (e.detail == 2) {
              console.log("on item dobleclick", item);
              if (item.type === "work") {
                setEditWorkData(item.workData)
                setIsEditModalOpen(true)
              }
            }

          }}
        >
          <div
            style={{
              height: itemContext.dimensions.height,
              paddingLeft: 5,
              paddingRight: 3,
              //backgroundColor:'red'
            }}
          >
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                color: "black",
                fontWeight: "bold",
              }}
              className="font-dm-regular"
            >
              {itemContext.title}
            </p>
          </div>
        </div>
        
{/*         {showTooltip && (
          <DrawTooltip
            id={"tooltip-" + item.id}
            type={item.type}
            data={item.data}
          />
        )} */}
              </>
    );
  },[]);

  return (
    <Content style={{ backgroundColor: "#282828" }}>
      <AddWorkModal
        addWorkData={addWorkData}
        isModalOpen={isAddModalOpen}
        setIsModalOpen={setIsAddModalOpen}
        projects={projects}
        groups={groups}
        refreshData={() => getWorks()}
      />

      <EditWorkModal
        editWorkData={editWorkData}
        isModalOpen={isEditModalOpen}
        setIsModalOpen={setIsEditModalOpen}
        projects={projects}
        groups={groups}
        refreshData={() => getWorks()}
      />



      <div className="schedule">
        <div
          className="tl-container"
          style={{ marginLeft: "5px", marginRight: "5px", marginTop: "20px" }}
        >


          {groups && groups.length &&  !linesLoading  >= 1 ? 
          <Timeline
            groups={groups}
            onCanvasClick={() => {}}
            items={lineData}
            itemRenderer={itemRenderer}
            groupRenderer={groupRenderer}
            defaultTimeStart={moment().hours(0)}
            defaultTimeEnd={moment().hours(0).add(4, "week")}
            canChangeGroup={false}
            minZoom={MIN_ZOOM}
            maxZoom={MAX_ZOOM}
            onItemSelect={() => {
              //console.log("item selected");
            }}
            onItemClick={null}
            stackItems
              timeSteps={{
                second: 1,
                minute: 1,
                hour: 1,
                day: 1,
                month: 1,
                year: 1,
              }}
              sidebarWidth={220}
              lineHeight={50}
              canMove={false}
              canResize={false}
          >
            <TimelineHeaders>
              <SidebarHeader>
                {({ getRootProps }) => {
                  return (
                    <div {...getRootProps()}>
                      <p className="timeline-left-header font-itc">
                        Henkilöstö
                      </p>
                    </div>
                  );
                }}
              </SidebarHeader>

              <CustomMarker date={TODAY}>
                {({ styles, date }) => (
                  <div
                    style={(styles = { ...styles, backgroundColor: "red" })}
                  />
                )}
              </CustomMarker>

              <DateHeader unit="week" />
              <DateHeader />
            </TimelineHeaders>
          </Timeline>
          : <><h1>Ladataan</h1></>}
        </div>
      </div>
    </Content>
  );
}

export default Schedule;
